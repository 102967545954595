import React from 'react';

const OverlayLoader = (isLogin = false) => {
  const loaderStyle = {
    zIndex: 1000,
    border: 'none',
    margin: 0,
    padding: 0,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    backgroundColor: 'rgb(255, 255, 255)',
    opacity: 0.6,
    cursor: 'wait',
    position: 'absolute'
  };

  const customStyle = {
    zIndex: 1011,
    position: 'absolute',
    padding: 0,
    margin: 0,
    width: '30%',
    top: isLogin ? "43%" : "50%",
    left: isLogin ? "35%" : "427px",
    textAlign: 'center',
    color: 'rgb(0, 0, 0)',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'wait'
  };
  return (
    <div>
      <div className="blockUI blockOverlay card" style={loaderStyle}></div>
      <div className="blockUI blockMsg blockElement" style={customStyle}>
       {/* <svg version="1.0" width="64px" height="64px" viewBox="0 0 128 128"><g><path d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#2f5dba"/><path
          d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#ccd7ee"
          transform="rotate(45 64 64)"/><path
          d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#ccd7ee"
          transform="rotate(90 64 64)"/><path
          d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#ccd7ee"
          transform="rotate(135 64 64)"/><path
          d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#ccd7ee"
          transform="rotate(180 64 64)"/><path
          d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#ccd7ee"
          transform="rotate(225 64 64)"/><path
          d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#ccd7ee"
          transform="rotate(270 64 64)"/><path
          d="M38.52 33.37L21.36 16.2A63.6 63.6 0 0 1 59.5.16v24.3a39.5 39.5 0 0 0-20.98 8.92z" fill="#ccd7ee"
          transform="rotate(315 64 64)"/><animateTransform attributeName="transform" type="rotate"
                                                           values="0 64 64;45 64 64;90 64 64;135 64 64;180 64 64;225 64 64;270 64 64;315 64 64"
                                                           calcMode="discrete" dur="640ms" repeatCount="indefinite"/></g></svg>*/}
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none"
             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
             className="spinner text-primary" style={{animation: 'spin 1s linear infinite'}}>
          <polyline points="23 4 23 10 17 10"></polyline>
          <polyline points="1 20 1 14 7 14"></polyline>
          <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
        </svg>
      </div>
    </div>
  );
};

export default OverlayLoader;
