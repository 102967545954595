import Axios from 'axios'
import { API_URL } from 'src/api/url'
import { QueryClient } from 'react-query'
const api = Axios.create({
 // baseURL: 'http://claim_laravel.test/api/',
  baseURL: API_URL.apiBasePath,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const errorCode = error?.response?.status
    if (errorCode === 401 || errorCode === 403) {
      localStorage.removeItem('token')
      window.location.href = '/login?returnUrl=' + window.location.pathname
    }
    return Promise.reject(error)
  },
)

Axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error)
  },
)

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
})

export { api, queryClient }
