export const API_URL = {
  //apiBasePath: 'http://alpha-omega-api.test/api/',
  apiBasePath: process.env.REACT_APP_API_URL,
  signIn: 'login',
  getUser: 'users/get',
  updateUser: 'platform-users/msq_platform_update_profile',
  updateUserPassword: 'platform-users/msq_platform_update_password',
  getTokens: 'platform-users/get-alltokens',

  //ClaimAPI
  getClaimsAllData: 'claims/all',
  getClaimsReportingData: 'claim_reporting/summary',
  exportClaimData: 'claims/export',
  getClaimData: 'claims/show',
  saveClaimData: 'claims/store',
  updateClaimData: 'claims/update',
  deleteClaimData: 'claims/destroy',
  getAllClaimTypeDate: 'claims/types',
  getClaimsZipCodes: 'claims/zipcodes',


  //UsersAPI
  getUsersAllData: 'users/all',
  exportUserData: 'users/export',
  getUserData: 'users/show',
  saveUserData: 'users/store',
  updateUserData: 'users/update',
  deleteUserData: 'users/destroy',

  //CarrierAPI
  getAllCarrierData: 'carrier/all',

  //PolicyAPI
  getAllPolicy: 'policy/all',

  //AgentsAPI
  getAllAgents: 'agents/all',

  //ClaimsYearsAPI
  getClaimYears: 'claims/years',

  //ClaimsInsuredNamesAPI
  getClaimInsuredNames: 'claims/insured-names',

  //ReportingAPI
  getAllReportSummary: 'claim_reporting/summary',
  getAllReportDetails: 'claim_reporting/agency/details',
  exportAllReportDetails: 'claim_reporting/export',

}
