import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider, ProtectRoute } from './common/context/'
import { QueryClientProvider } from 'react-query'
import { queryClient } from 'src/api'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login/Login'))
const ReportClaims = React.lazy(() => import('./views/claims/reporting/ReportClaim'))


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
        <AuthProvider>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact index path="login" name="Login Page" element={<Login />} />
            <Route  element={<ProtectRoute />}>
              <Route index exact path="dashboard" element={ <ReportClaims />} />
              <Route index path="*" name="Home" element={<DefaultLayout />} />
            </Route>
          </Routes>
        </Suspense>
        </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    )
  }
}

export default App
