import React from 'react'

const Claims = React.lazy(() => import('./views/claims/Claims'))
const AddClaims = React.lazy(() => import('./views/claims/add-claim/AddClaim'))
const EditClaims = React.lazy(() => import('./views/claims/edit-claim/EditClaim'))
const ReportClaims = React.lazy(() => import('./views/claims/reporting/ReportClaim'))
const Users = React.lazy(() => import('./views/users/Users'))
const AddUser = React.lazy(() => import('./views/users/add-user/AddUser'))
const EditUser = React.lazy(() => import('./views/users/edit-user/EditUser'))


const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', name: 'Dashboard', element:ReportClaims  },
  { path: '/claims', name: 'Claims', element: Claims },
  { path: '/claims/new', name: 'Add Claim', element: AddClaims },
  { path: '/claims/edit-claim/:id', name: 'Edit Claims', element: EditClaims },
  { path: '/claims/reporting', name: 'Claims Report', element: ReportClaims },
  { path: '/users', name: 'Users', element: Users },
  { path: '/users/new', name: 'Add User', element: AddUser },
  { path: '/users/edit-user/:id', name: 'Edit Users', element: EditUser },
]

export default routes
