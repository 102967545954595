import { useNavigate,Navigate  } from 'react-router-dom'
import React, { createContext, useState, useEffect, useContext,useMemo  } from 'react'
import { api, API_URL } from '../../../api'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../../../components/index'
import OverlayLoader from "../../../components/OverlayLoader";
import DefaultLayout from "../../../layout/DefaultLayout";

// ** Defaults
const defaultProvider = {
  isAuthenticated: false,
  isOtpVerified: false,
  userRole: null,
  user: null,
  loading: true,
  login: async (email, password) => {},
  logout: async () => {},
  updateUser: async (f) => false,
}

const AuthContext = createContext(defaultProvider)

const PUBLIC_PATHNAME = ['/login']

const mockUser = {}

export const AuthProvider = ({children}) => {
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const [userRole, setuserRole] = useState(null)

  const [loading, setLoading] = useState(true)

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]))
    } catch (e) {
      return null
    }
  }

  useEffect(() => {
    async function verifyToken() {
      const token = localStorage.getItem('token')
      const decodedJwt = parseJwt(token || '')
      const isTokenValid = decodedJwt && decodedJwt?.exp * 1000 > Date.now()
      if (token && isTokenValid) {
        api.defaults.headers.Authorization = `Bearer ${token}`

        const res = await api.get(API_URL.getUser)

        if (res.status == 200) {
          const user = res.data
          console.log(user);
          setUser(user)
        } else {
          setUser(mockUser)
          setLoading(false)
        }
        //TODO: Get user and setUser
      }else{
        navigate("/login")
      }
      setLoading(false)
    }
    verifyToken()
  }, [])



  const handleLogin = (userData) => {
    localStorage.setItem('token', userData.token)
    api.defaults.headers.Authorization = `Bearer ${userData.token}`
    //TODO: Get user and setUser
    setUser(userData)
    setTimeout(() => {
      navigate("/dashboard")
    }, 0)
  }

  const login = async (email, password) => {
    //console.log(email); return false;
    const res = await api.post(API_URL.signIn, { email, password });

    if (res.data.success == true) {
      handleLogin(res.data.data)
      }else if(res.data.success == false) {
      return res.data;
    }
  }


  const logout = async () => {
    localStorage.removeItem('token')
    setUser(null)
    delete api.defaults.headers.Authorization
    navigate("/login",{replace:true})

  }

  const updateUser = async (formData) => {
     const res = await api.post(API_URL.updateUser, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    if (res.data.status == 'success') {
      const userData = res.data.user
      setuserRole(userData.roles)
      setUser(userData)
      return true
    }
    return false
  }
  const value = useMemo(
    () => ({
      isAuthenticated: !!user,
      isOtpVerified: !!user,
      userRole,
      user,
      login,
      loading,
      logout,
      updateUser,
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={value} >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });
  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };
  return [storedValue, setValue];
};

export const ProtectRoute = ( { children }) => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();

  return (
    /*isAuthenticated?*/
    <div>
      <DefaultLayout />
    </div>
      /*: <Navigate to="/login"/>*/
  );

  //return <>{children}</>
}
