import React, {useState} from 'react'
import { AppContent, AppSidebar, AppFooter, AppHeader } from '../components/index'
import OverlayLoader from "../components/OverlayLoader";
import { api } from '../api'

const DefaultLayout = () => {
  const [isApiLoading, setApiLoading] = useState(true);


  api.interceptors.request.use((config) => {
    setApiLoading(true)
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      setApiLoading(false)
      return response;
    },
    async (error) => {
      setApiLoading(false)
      return Promise.reject(error)
    },
  )


  return (
    <div>
      <AppSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <AppHeader />
        <div className="body flex-grow-1 px-3">
          <div className='card' style={{ background: 'transparent', border: 'none'}}>
            <AppContent />
            {isApiLoading && <OverlayLoader />}
          </div>
        </div>
        <AppFooter />
      </div>
    </div>
  )
}

export default DefaultLayout
